@font-face {
  font-family: "Diatype";
  src:
    url("../fonts/diatype/ABCDiatype-Thin.woff2") format("woff2"),
    url("../fonts/diatype/ABCDiatype-Thin.woff") format("woff");
  font-weight: 200;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
  font-family: "Diatype";
  src:
    url("../fonts/diatype/ABCDiatype-ThinItalic.woff2") format("woff2"),
    url("../fonts/diatype/ABCDiatype-ThinItalic.woff") format("woff");
  font-weight: 200;
  font-style: italic;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
  font-family: "Diatype";
  src:
    url("../fonts/diatype/ABCDiatype-Light.woff2") format("woff2"),
    url("../fonts/diatype/ABCDiatype-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
  font-family: "Diatype";
  src:
    url("../fonts/diatype/ABCDiatype-LightItalic.woff2") format("woff2"),
    url("../fonts/diatype/ABCDiatype-LightItalic.woff") format("woff");
  font-weight: 300;
  font-style: italic;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
  font-family: "Diatype";
  src:
    url("../fonts/diatype/ABCDiatype-Regular.woff2") format("woff2"),
    url("../fonts/diatype/ABCDiatype-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
  font-family: "Diatype";
  src:
    url("../fonts/diatype/ABCDiatype-RegularItalic.woff2") format("woff2"),
    url("../fonts/diatype/ABCDiatype-RegularItalic.woff") format("woff");
  font-weight: 400;
  font-style: italic;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
  font-family: "Diatype";
  src:
    url("../fonts/diatype/ABCDiatype-Medium.woff2") format("woff2"),
    url("../fonts/diatype/ABCDiatype-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
  font-family: "Diatype";
  src:
    url("../fonts/diatype/ABCDiatype-MediumItalic.woff2") format("woff2"),
    url("../fonts/diatype/ABCDiatype-MediumItalic.woff") format("woff");
  font-weight: 500;
  font-style: italic;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
  font-family: "Diatype";
  src:
    url("../fonts/diatype/ABCDiatype-Bold.woff2") format("woff2"),
    url("../fonts/diatype/ABCDiatype-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
  font-family: "Diatype";
  src:
    url("../fonts/diatype/ABCDiatype-BoldItalic.woff2") format("woff2"),
    url("../fonts/diatype/ABCDiatype-BoldItalic.woff") format("woff");
  font-weight: 700;
  font-style: italic;
  font-stretch: normal;
  font-display: swap;
}

/* Semi-Mono */

@font-face {
  font-family: "DSemi";
  src:
    url("../fonts/semimono/ABCDiatypeSemi-Mono-Thin.woff2") format("woff2"),
    url("../fonts/semimono/ABCDiatypeSemi-Mono-Thin.woff") format("woff");
  font-weight: 200;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
  font-family: "DSemi";
  src:
    url("../fonts/semimono/ABCDiatypeSemi-Mono-ThinItalic.woff2")
      format("woff2"),
    url("../fonts/semimono/ABCDiatypeSemi-Mono-ThinItalic.woff") format("woff");
  font-weight: 200;
  font-style: italic;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
  font-family: "DSemi";
  src:
    url("../fonts/semimono/ABCDiatypeSemi-Mono-Light.woff2") format("woff2"),
    url("../fonts/semimono/ABCDiatypeSemi-Mono-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
  font-family: "DSemi";
  src:
    url("../fonts/semimono/ABCDiatypeSemi-Mono-LightItalic.woff2")
      format("woff2"),
    url("../fonts/semimono/ABCDiatypeSemi-Mono-LightItalic.woff") format("woff");
  font-weight: 300;
  font-style: italic;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
  font-family: "DSemi";
  src:
    url("../fonts/semimono/ABCDiatypeSemi-Mono-Regular.woff2") format("woff2"),
    url("../fonts/semimono/ABCDiatypeSemi-Mono-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
  font-family: "DSemi";
  src:
    url("../fonts/semimono/ABCDiatypeSemi-Mono-RegularItalic.woff2")
      format("woff2"),
    url("../fonts/semimono/ABCDiatypeSemi-Mono-RegularItalic.woff")
      format("woff");
  font-weight: 400;
  font-style: italic;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
  font-family: "DSemi";
  src:
    url("../fonts/semimono/ABCDiatypeSemi-Mono-Medium.woff2") format("woff2"),
    url("../fonts/semimono/ABCDiatypeSemi-Mono-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
  font-family: "DSemi";
  src:
    url("../fonts/semimono/ABCDiatypeSemi-Mono-MediumItalic.woff2")
      format("woff2"),
    url("../fonts/semimono/ABCDiatypeSemi-Mono-MediumItalic.woff")
      format("woff");
  font-weight: 500;
  font-style: italic;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
  font-family: "DSemi";
  src:
    url("../fonts/semimono/ABCDiatypeSemi-Mono-Bold.woff2") format("woff2"),
    url("../fonts/semimono/ABCDiatypeSemi-Mono-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
  font-family: "DSemi";
  src:
    url("../fonts/semimono/ABCDiatypeSemi-Mono-BoldItalic.woff2")
      format("woff2"),
    url("../fonts/semimono/ABCDiatypeSemi-Mono-BoldItalic.woff") format("woff");
  font-weight: 700;
  font-style: italic;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
  font-family: "Monument";
  src:
    url("../fonts/monument/MonumentExtended-Regular.woff2") format("woff2"),
    url("../fonts/monument/MonumentExtended-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
  font-family: "Monument";
  src:
    url("../fonts/monument/MonumentExtended-Bold.woff2") format("woff2"),
    url("../fonts/monument/MonumentExtended-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
  font-family: "Monument";
  src:
    url("../fonts/monument/MonumentExtended-Ultralight.woff2") format("woff2"),
    url("../fonts/monument/MonumentExtended-Ultralight.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
}

/* ABC Mono */

@font-face {
  font-family: "ABC Mono";
  src: url("../fonts/abcmono/ABCFavoritMonoVariable-Regular.woff2"),
    url("../fonts/abcmono/ABCFavoritMonoVariable-Regular.woff");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "ABC Mono";
  src: url("../fonts/abcmono/ABCFavoritMonoVariable-Regular.woff2"),
    url("../fonts/abcmono/ABCFavoritMonoVariable-Regular.woff");
  font-weight: 100;
  font-style: normal;
}
