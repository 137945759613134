.alertOuter {
  position: relative;
  width: 100%;
  height: auto;
  display: grid;
  grid-template-rows: 0fr;
  opacity: 0;
  transition:
    grid-template-rows 0.35s ease-out,
    opacity 0.25s linear;
  animation: slideIn 0.35s ease-out forwards;
  z-index: 1;
}

.alertInner {
  overflow: hidden;
}

@keyframes slideIn {
  to {
    grid-template-rows: 1fr;
    opacity: 1;
  }
}
