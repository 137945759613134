.cookie-consent {
  position: fixed;
  z-index: 1200;
  bottom: 10px;
  left: 10px;
  right: 10px;

  @media (min-width: 768px) {
    max-width: 400px;
    right: auto;
  }
}
